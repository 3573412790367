/* Change this file to get your personal Portfolio */

import emoji from "react-easy-emoji";

// Your Summary And Greeting Section

const greeting = {
  username: "",
  title: `Hi all, I'm ${ process.env.REACT_APP_USER_NAME }`,
  subTitle: emoji(
    `${ process.env.REACT_APP_USER_ROLES }`
  ),
  resumeLink:
    `${ process.env.REACT_APP_RESUME_URL }`,
};

// Your Social Media Link

const socialMediaLinks = {
  github: process.env.REACT_APP_GITHUB_URL,
  linkedin: process.env.REACT_APP_LINKEDIN_URL,
  gmail: process.env.REACT_APP_EMAIL_ADDRESS,
  facebook: process.env.REACT_APP_FACEBOOK_URL,
  twitter: process.env.REACT_APP_TWITTER_URL,
};

// Your Skills Section

const skillsSection = {
  title: "",
  subTitle:
    "",
  skills: [
    // emoji(
    //   "⚡ Designing and developing Test Automation frameworks using Python and Java"
    // ),
    // emoji("⚡ End to End automation executions"),
    // emoji(
    //   "⚡ Working on test automation tools like Selenium, Appium, UFT, SoapUI, AutoIT, Katalon Studio, Seetest Mobile Cloud administration, etc"
    // ),
    // emoji(
    //   "⚡ Building predictive models to extract insights and make decisions out of data"
    // ),
  ],

  softwareSkills: [
    {
      skillName: "Docker",
      classname: "logos:docker",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Kubernetes",
      classname: "logos:kubernetes",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Terraform",
      classname: "logos:terraform",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Ansible",
      classname: "logos:ansible",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Prometheus",
      classname: "logos:prometheus",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Grafana",
      classname: "logos:grafana",
      style: {
        backgroundColor: "transparent",
        //color: "#D00000",
      },
    },
    {
      skillName: "Loki",
      classname: "logos:loki",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Signoz",
      classname: "logos:signoz",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Sentry",
      classname: "logos:sentry",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Jaeger",
      classname: "logos:jaeger",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Zipkin",
      classname: "logos:zipkin",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Django",
      classname: "logos:django-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Slack API",
      classname: "logos:slack",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "AWS",
      classname: "logos:aws",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "GCP",
      classname: "logos:gcp",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Hetzner",
      classname: "logos:hetzner",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Laravel",
      classname: "logos:laravel",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "MongoDB",
      classname: "logos:mongodb-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "MySQL",
      classname: "logos:mysql-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Postgres",
      classname: "logos:postgresql",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Postman",
      classname: "logos:postman-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Git",
      classname: "logos:git-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Linux",
      classname: "logos:linux-tux",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Jenkins",
      classname: "vscode-icons:file-type-jenkins",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Nginx",
      classname: "logos:nginx",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Selenium",
      classname: "logos:selenium",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Azure",
      classname: "logos:microsoft-azure",
      style: {
        backgroundColor: "transparent",
      },
    },
  ],
};

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "DevOps Technical Lead",
      company: "Hotels.ng",
      companylogo: require("./assets/images/HNGLogo.svg"),
      date: "December 2022 – Present",
      desc:
        "I successfully worked with a team of 10 developers, implemented continuous integration and delivery (CI/CD) pipelines that resulted in a 40% increase in software delivery frequency, and collaborated with multiple departments to deliver a cloud-based infrastructure that saved the company approximately $50k annually on server maintenance costs and downtime control; while maintaining effective communication, meeting up with deadlines, and performing critical analysis when implementing security.",
      descBullets: [
        "Created CI/CD pipelines for Python FastAPI, PHP Laravel, ReactJS and Flutter Web applications using GitHub actions and Semantic Release, resulting in a 50% reduction in deployment time, improved software quality, and faster software release.", 
        "Integrated the alerting system with Slack, to trigger events for our applications and services and notify on-call engineers in real-time, resulting in faster incident response and resolution times.", 
        "Created comprehensive documentation that allows future developers to understand the work done.",
        "Using tools such as Prometheus, and Grafana, we maintain 24-hour operations, monitor all applications, and ensure 98.9% uptime for the servers.",
        "Streamlined automated deployments across a fleet of 5+ servers throughout multiple DevOps environments, increasing speed-to-market by 33%.",
        "Helped the developers in building and optimizing react and fast API applications.",
        "Deployed flutter code to the web and automated Android mobile release on Github.",
        "Monitor and maintain containerised microservices using docker and docker-compose, and load balancing them across multiple servers",
      ],
    },
    {
      role: "DevOps Engineer",
      company: "Love-me.app",
      companylogo: require("./assets/images/love-me.appLogo.svg"),
      date: "Nov. 2022 – Oct. 2023",
      descBullets: [
        "Full lifecycle implementation from requirements analysis, platform selection, technical architecture design, application design & development, testing, and deployment.",
        "Created CI/CD pipelines for deploying FastAPI and ReactJS applications to AWS EC2 and AWS CodeDeploy.",
        "Deployed the company website on EC2 instances and managed deployments with Github-Actions.",
        "Used Kubernetes Helm chart (IaC) to deploy infrastructure for applications.",
        "Deployed APIs through the AWS API Gateway",
        "Refactored infrastructure to lower costs",
        "Setup RabbitMQ broker, Celery and Redis for backend applications",
        "Provisioned servers (EC2) and databases (RDS) for the company’s projects",
       "Once Setup CI/CD pipelines for backend applications using AWS CodeDeploy, CodeBuild and CodePipeline",
      ],
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to false
  display: true, // Set false to hide this section, defaults to true
};

// Your certifications

const certifications = {
  certifications: [
    {
      title: "DevOps Engineer",
      subtitle: "- Hng Internship 2022",
      logo_path: "hng.png",
      certificate_link:
        "",
      alt_name: "HNG Devops Engineer Certified",
      color_code: "#FFBF00",
    },
    {
      title: "Full Stack Web Developer",
      subtitle: "- Zuri Training",
      logo_path: "zuri.png",
      certificate_link:
        "",
      alt_name: "Zuri",
      color_code: "#008080",
    },
    // {
    //   title: "DevOps Engineer",
    //   subtitle: "- HNG Internship",
    //   logo_path: "hng.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1c1vIFekoOVpSxuuQfroxzBYUJcber05_/view",
    //   alt_name: "HNG",
    //   color_code: "#FFFFFF",
    // },
    // {
    //   title: "Cloud DevOps Engineer",
    //   subtitle: "- Udacity Nanodegree",
    //   logo_path: "alx.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/1iFgSyagtarHixn7j0aJnQsYrWeHOZnfS/view",
    //   alt_name: "ALX",
    //   color_code: "#5DADE2",
    // },
    // {
    //   title: "Data Science",
    //   subtitle: "- IBM",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://coursera.org/share/893f2210f39f5a60d0463b19b469b808",
    //   alt_name: "IBM",
    //   color_code: "#E3F1FA",
    // },
    // {
    //   title: "ML and DS",
    //   subtitle: "- Andrei Neagoie",
    //   logo_path: "zero-to-mastery.png",
    //   certificate_link:
    //     "https://www.udemy.com/certificate/UC-fd118b7d-7436-442c-a7f8-5077c432d596/",
    //   alt_name: "Zero To Mastery",
    //   color_code: "#FFFDDD",
    // },
  ],
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url:
        "",
      title: "",
      description:
        "",
    },
    {
      url:
        "",
      title: "",
      description:
        "",
    },
    {
      url:
        "",
      title: "",
      description:
        "",
    },
    {
      url:
        "",
      title: "",
      description:
        "",
    },
  ],
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+2347062141157",
  email_address: process.env.REACT_APP_EMAIL_ADDRESS,
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  workExperiences,
  openSource,
  blogSection,
  contactInfo,
  certifications,
};
